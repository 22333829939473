<template>
  <div class="commentDetails">
    <HeadBar titleName="评论详情"></HeadBar>
    <div class="msgTopBox">
      <div @click="jumpUserPage(msgInfo)">
        <ImgDecypt
          class="avatar"
          :src="msgInfo.userPortrait"
          :key="msgInfo.userPortrait"
          round
        />
      </div>
      <div class="infoBox">
        <div class="uName">{{ msgInfo.userName }}</div>
        <div class="dateInfo">{{ msgInfo.createdAt | commentTime }}</div>
      </div>
      <div class="likeBox" @click="likeComment(msgInfo)">
        <svg-icon
          class="likeIcon"
          :iconClass="msgInfo.isLike ? 'redHeart' : 'grey_heart'"
        />
        <div>{{ msgInfo.likeCount }}</div>
      </div>
    </div>
    <div class="topMsg">{{ msgInfo.content }}</div>
    <div class="msgLv2">
      <div class="msgLv2total">共{{ msgInfo.commCount }}条回复</div>
      <div class="msgList">
        <PullRefresh
          :loading="loading"
          :refreshing="refreshing"
          :finished="finished"
          @onLoad="onLoad"
          @onRefresh="onRefresh"
          :isHigehtMax="true"
          :isNoData="isNoData"
          :error="error"
        >
          <div class="msgItem" v-for="(item, index) in list" :key="index">
            <div class="msgTopBox">
              <div @click="jumpUserPage(item)">
                <ImgDecypt
                  class="avatar"
                  :src="item.userPortrait"
                  :key="item.userPortrait"
                  round
                />
              </div>
              <div class="infoBox">
                <div class="uName">{{ item.userName }}</div>
                <div class="dateInfo">{{ item.createdAt | commentTime }}</div>
              </div>
              <div class="likeBox" @click="likeComment(item)">
                <svg-icon
                  class="likeIcon"
                  :iconClass="item.isLike ? 'redHeart' : 'grey_heart'"
                />
                <div>{{ item.likeCount }}</div>
              </div>
            </div>
            <div class="topMsg">{{ item.content }}</div>
          </div>
        </PullRefresh>
      </div>
    </div>
    <div class="tabBar" @click="showInput">
      <div class="comments">
        <span>发条有爱的评论吧</span>
        <img src="@/assets/png/commentsEditor.png" />
      </div>
      <div class="placeholder"></div>
    </div>
    <van-popup
      class="inputPop"
      v-model="isShowInput"
      position="bottom"
      @close="closeInput"
    >
      <div class="inputBox">
        <van-field
          class="newInput"
          ref="newInput"
          v-model="msgVal"
          :border="false"
          placeholder="我怀疑你也想开车"
        />
        <div class="sendBtn" :class="{ activeBtn: msgVal }" @click="sendMsg">
          发送
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import HeadBar from "@/components/HeadBar";
import ImgDecypt from "@/components/ImgDecypt";
import PullRefresh from "@/components/PullRefresh";
import { getBeijinTime } from "@/utils";
import { Popup, Field, Toast } from "vant";
import {
  queryComment,
  sendComment,
  thumbsUpComment,
  thumbsDownComment,
} from "@/api/mine";
import { queryCommentListlever2 } from "@/api/user";
export default {
  components: {
    HeadBar,
    ImgDecypt,
    PullRefresh,
  },
  data() {
    return {
      msgInfo: {},
      list: [],
      pageNumber: 1,
      pageSize: 10,
      loading: true,
      refreshing: false,
      finished: false,
      error: false,
      isNoData: false,
      isShowInput: false,
      msgVal: "",
      cid: undefined,
    };
  },
  created() {
    let dataStr = this.$route.query.msgInfo;
    let decodeData = window.atob(decodeURI(dataStr));
    this.msgInfo = JSON.parse(decodeURI(window.decodeURI(decodeData)));
    // console.log(this.msgInfo)
    this.getList("refresh");
  },
  methods: {
    // 查询列表
    async getList(type) {
      let req = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        curTime: getBeijinTime(),
        objID: this.msgInfo.objID,
        cmtId: this.msgInfo.id,
        fstID: this.msgInfo.Info[0].id,
      };
      try {
        let res = await this.$Api(queryCommentListlever2, req);
        this.loading = false;
        this.refreshing = false;
        if (res.code === 200) {
          let list = res.data.list || [];
          if (type === "refresh") {
            this.list = this.msgInfo.Info.concat(list);
          } else {
            this.list = this.list.concat(list);
          }
          if (this.list.length <= 0) {
            this.isNoData = true;
          }
          if (!res.data.hasNext) {
            this.finished = true;
            return;
          }
        } else {
          this.error = true;
        }
      } catch (error) {
        this.error = true;
        this.loading = false;
      }
    },
    // 上啦加载
    onLoad() {
      this.loading = true;
      if (this.error) {
        this.error = false;
      } else {
        this.pageNumber++;
      }
      this.getList();
    },
    // 下拉刷新
    onRefresh() {
      this.pageNumber = 1;
      this.refreshing = true;
      this.finished = false;
      this.loading = true;
      this.getList("refresh");
    },
    async likeComment(item) {
      let req = {
        objID: item.id,
        type: "comment",
      };
      this.$store.commit("app/SET_LOADING", true);
      try {
        let res = await this.$Api(
          item.isLike ? thumbsDownComment : thumbsUpComment,
          req
        );
        this.$store.commit("app/SET_LOADING", false);
        if (res.code === 200) {
          if (item.isLike) {
            item.likeCount = item.likeCount - 1 || 0;
          } else {
            item.likeCount = item.likeCount + 1;
          }
          item.isLike = !item.isLike;

          Toast(item.isLike ? "点赞成功" : "取消点赞");
        } else {
          Toast(res.tip || "操作失败，请稍后再试");
        }
      } catch (error) {
        this.$store.commit("app/SET_LOADING", false);
        Toast("操作失败，请稍后再试");
      }
    },
    showInput() {
      // this.level = 1;
      this.isShowInput = true;
      this.$nextTick(() => {
        if (this.$refs.newInput) {
          this.$refs.newInput.focus();
        }
      });
    },
    closeInput() {
      this.cid = undefined;
      // this.userId = undefined;
      this.msgVal = "";
      // this.level = 1;
    },
    async sendMsg() {
      let req = {
        cid: this.msgInfo.id,
        content: this.msgVal,
        level: 2,
        objID: this.msgInfo.objID,
        cmtType: "media",
      };

      if (!req.content) return;
      this.$store.commit("app/SET_LOADING", true);
      try {
        let res = await this.$Api(sendComment, req);
        this.$store.commit("app/SET_LOADING", false);
        if (res.code === 200) {
          // if (this.msgInfo) {
          //     this.list.unshift(res.data);
          //     this.msgInfo.commCount = this.msgInfo.commCount + 1;
          // } else {
          //     this.list.unshift(res.data);
          // }
          this.list.unshift(res.data);
          this.msgInfo.commCount = this.msgInfo.commCount + 1;
          // this.msgTotle = this.msgTotle + 1;
          this.isShowInput = false;
          Toast("评论成功");
        } else {
          Toast(res.tip || "评论失败，请稍后再试");
        }
      } catch (error) {
        this.$store.commit("app/SET_LOADING", false);
        Toast("评论失败，请稍后再试");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.commentDetails {
  height: 100%;

  .msgTopBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    box-sizing: border-box;
    // margin-bottom: 6px;

    .avatar {
      width: 34px;
      height: 34px;
      margin-right: 18px;
    }

    .infoBox {
      flex: 2;
      color: #a09fb1;

      .uName {
        color: #000;
        font-size: 15px;
      }

      .dateInfo {
        font-size: 12px;
      }
    }

    .likeBox {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #a09fb1;
      font-size: 12px;

      .likeIcon {
        width: 16.2px;
        height: 14.8px;
        margin-bottom: 1.6px;
      }
    }
  }

  .topMsg {
    color: #000;
    font-size: 15px;
    margin: 0 0 12px 64px;
  }

  .msgLv2 {
    height: calc(100% - 200px);
    color: #000;
    background: #e6e6e6;
    padding: 0 12px;
    box-sizing: border-box;

    .msgLv2total {
      font-size: 15px;
      padding: 12px 0;
      box-sizing: border-box;
    }

    .msgList {
      height: calc(100% - 39px);
    }
  }

  .tabBar {
    background: rgb(240, 240, 244);
    position: absolute;
    bottom: 0;
    height: 60px;
    width: 100%;
    z-index: 2;

    .comments {
      height: 59px;
      display: flex;
      justify-content: space-between;
      padding: 19px 24px;
      color: rgb(153, 153, 153);
      //font-weight: bold;
      font-size: 15px;

      img {
        width: 18.2px;
        height: 18.2px;
      }
    }

    .placeholder {
      height: 34px;
    }
  }
  .inputPop {
    .inputBox {
      height: 59px;
      padding: 0 12px;
      box-sizing: border-box;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .newInput {
        width: 273px;
        height: 31px;
        border-radius: 31px;
        padding: 0 12px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        background: #e6e6e6;

        /deep/ .van-field__control {
          font-size: 12px;
          color: #000;
        }

        ::placeholder {
          color: #999;
        }
      }

      .sendBtn {
        width: 66px;
        height: 33px;
        border-radius: 33px;
        font-size: 15px;
        color: #fff;
        background: #757494;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .activeBtn {
        color: #fff;
        background: #7145e7;
      }
    }
  }
}
</style>
